<template>
  <section class="split-records">
    <search />
    <section class="split-records-table">
      <vxe-grid ref="xTable" v-loading="loading" border :data="dataSource" :columns="tableColumn" size="small" v-bind="tableOptions">
        <template #splitAmount="{ row }">
          <span>¥{{ row.splitAmount }}</span>
        </template>
        <template #toolbar_buttons>
          <el-button icon="el-icon-plus" size="small" type="default" @click="handleExport">导出 </el-button>
        </template>
        <template #orderNo="{ row }">
          <el-link v-if="row.orderType == 2" :underline="false" type="primary" @click="goOrderDetail(row)">{{ row.orderNo }}</el-link>
          <span v-else>--</span>
        </template>

        <template #serviceNo="{ row }">
          <el-link :underline="false" type="primary" @click="goServiceDetail(row)">{{ row.serviceNo }}</el-link>
        </template>
        <!-- <template #paymentOrdeNo="{ row }">
          <el-link :underline="false" type="primary" @click="goPayDetail(row)">{{
            row.paymentOrdeNo
          }}</el-link>
        </template> -->
        <template #withdrawStateName="{ row }">
          <span :style="{ color: getStateColor(row.withdrawState) }">{{ row.withdrawState == 0 ? '待确认' : row.withdrawStateName }}</span>
        </template>

        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </section>
  </section>
</template>

<script>
import IPagination from '@/components/i-pagination/index.vue'
import Search from './components/search/index.vue'
import TableListMixin from '@/minixs/TableListMinix'
import { exportGetList } from '@/api/pay'
import { downloadFile } from '@/utils/fileDownload'

export default {
  name: 'SplitRecords',
  components: {
    Search,
    IPagination,
  },
  mixins: [TableListMixin],
  data() {
    return {
      url: {
        list: 'order-web-api/admin/orderserviceproportion/getList',
      },
      tableColumn: [
        { type: 'checkbox', minWidth: 50, fixed: 'left' },
        { field: 'splitNo', minWidth: 220, title: '分账编号', fixed: 'left' },
        {
          field: 'paymentOrdeNo',
          minWidth: 220,
          title: '支付流水号',
          // slots: { default: 'paymentOrdeNo' }
        },
        { field: 'serviceNo', minWidth: 220, title: '服务订单ID', slots: { default: 'serviceNo' } },
        { field: 'orderNo', minWidth: 220, title: '加购订单ID', slots: { default: 'orderNo' } },
        { field: 'productName', minWidth: 220, title: '单品名称' },
        { field: 'storeId', minWidth: 100, title: '供应商ID' },
        { field: 'storeName', minWidth: 180, title: '供应商名称' },
        {
          field: 'splitAmount',
          minWidth: 150,
          title: '分账金额',
          slots: { default: 'splitAmount' },
        },
        { field: 'serviceCompleteTime', minWidth: 150, title: '订单完成时间' },
        { field: 'splitTime', minWidth: 150, title: '分账时间' },
        {
          field: 'withdrawStateName',
          slots: { default: 'withdrawStateName' },
          minWidth: 120,
          title: '状态',
        },
      ],
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons',
          },
        },
      },
    }
  },
  methods: {
    getStateColor(key) {
      switch (key) {
        case 0:
          return '#d9001b'
        case 1:
          return '#9090ff'
        case 5:
          return '#f59a23'
        case 2:
          return '#70b603'

        default:
          break
      }
    },
    goOrderDetail(row) {
      const url = '/index/service/purchaseOrder/purchaseParticulars?id='

      history.pushState(null, null, url + row.orderId)
    },
    goPayDetail(row) {
      this.$router.push({
        path: '/record/detail',
        query: { id: row.paymentOrdeNo, orderNo: row.orderNo },
      })
    },
    goServiceDetail(row) {
      history.pushState(null, null, `/index/service/serviceOrder/serviceParticulars?id=${row.serviceId}`)
    },
    handleExport() {
      const selectRecords = this.$refs.xTable.getCheckboxRecords()
      if (selectRecords.length === 0) {
        this.$message({
          message: '请至少选中一条数据',
          type: 'warning',
        })
        return
      }

      const ids = []
      selectRecords.forEach((e) => {
        ids.push(e.id)
      })
      this.loading = true
      exportGetList({
        data: { ids },
      })
        .then((res) => {
          downloadFile(res, '分账记录', '.xlsx')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
