<template>
  <div class="search">
    <el-form :model="form" class="demo-form-inline" size="small" inline>
      <div class="top">
        <el-form-item label="状态：" class="form-item">
          <el-select v-model="form.withdrawState" placeholder="请选择" class="form-item-select">
            <el-option label="全部" value=""></el-option>
            <el-option label="待确认" value="0"></el-option>
            <el-option label="未提现" value="5"></el-option>
            <el-option label="提现审核中" value="1"></el-option>
            <el-option label="提现中" value="2"></el-option>
            <el-option label="已提现" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分账编号：" class="form-item">
          <el-input v-model="form.splitNo" placeholder="请输入" class="form-item-input"></el-input>
        </el-form-item>
        <el-form-item label="支付流水号：" class="form-item">
          <el-input
            v-model="form.paymentOrdeNo"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务订单ID：" class="form-item">
          <el-input
            v-model="form.serviceNo"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="加购订单ID：" class="form-item">
          <el-input v-model="form.orderNo" placeholder="请输入" class="form-item-input"></el-input>
        </el-form-item>
        <el-form-item class="form-item" label-width="30px">
          <el-button icon="el-icon-search" type="primary" size="small" @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh-left" size="small" @click="reset">重置</el-button>
          <el-link type="primary" style="margin-left: 10px" @click="checkFold"
            >{{ fold ? '收起' : '展开' }}
          </el-link>
        </el-form-item>
      </div>

      <div v-show="fold" class="more">
        <el-form-item label="供应商ID：" class="form-item">
          <el-input v-model="form.storeId" placeholder="请输入" class="form-item-input"></el-input>
        </el-form-item>
        <el-form-item label="订单完成时间：">
          <el-date-picker
            v-model="form.time1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="分账时间：">
          <el-date-picker
            v-model="form.time2"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: {
        storeId: ''
      },
      fold: false,
      tags: [{ name: '标签一' }, { name: '标签二' }]
    };
  },
  mounted() {
    const data = this.$route.query;
    if (data) {
      this.form.storeId = data.storeId;
      this.form.withdrawState = data.withdrawState ? data.withdrawState : '';
    }
  },
  methods: {
    search() {
      const data = { ...this.form };
      if (data.time1) {
        data.beginServiceCompleteTime = moment(data.time1[0]).format('YYYY-MM-DD HH:mm:ss');
        data.endServiceCompleteTime = moment(data.time1[1]).format('YYYY-MM-DD HH:mm:ss');
      }

      if (data.time2) {
        data.beginSplitTime = moment(data.time2[0]).format('YYYY-MM-DD HH:mm:ss');
        data.endSplitTime = moment(data.time2[1]).format('YYYY-MM-DD HH:mm:ss');
      }
      delete data.time1;
      delete data.time2;
      this.$parent.handleSearch(data);
    },
    reset() {
      this.form = {};
      this.$parent.handleRest(this.form);
    },
    checkFold() {
      this.fold = !this.fold;
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
</style>
